<template>
    <div class="c-panel__content c-panel__content--notifications" id="notifications">
        <div class="c-panel__wrap" v-if="notifications.length === 0">
            <a href="#" class="c-panel__notification">
                <div class="c-panel__notification-wrap" v-if="$root.user !== false">
                    No new notifications on your account
                </div>
                <div class="c-panel__notification-wrap" v-else>
                    Notifications on your account will appear once logged in
                </div>
            </a>
        </div>
        <div class="c-panel__wrap" v-for="(notification, index) in notifications">
            <a href="#" :class="'c-panel__notification' + (notification.read === false ? ' new' : '')">
                <div class="c-panel__notification-wrap">
                    <div class="c-panel__notification-content button">
                        <div class="c-panel__notification-detail">
                            <div class="c-avatar">
                                <img :src="$simpleStore.cloudinaryUrl(23, 23, notification.notifierAvatar, $root)" alt="">
                            </div>
                            <p>
                                <strong v-html="notifiers[index]"></strong>
                                <img v-if="typeof notification.icon != 'undefined'" :src="notification.icon" alt="">
                                <span>{{ notification.message }}</span>
                                <a v-if="typeof notification.communityUrl != 'undefined'" :href="'/' + notification.communityUrl">{{ notification.communityName }}</a>
                            </p>
                        </div>
                    </div>
                    <div class="c-panel__notification-btn" v-html="buttons[index]" @click="handleClick($event, index)"></div>
                </div>
                <div class="c-notifications-article" v-if="typeof notification.article != 'undefined'">
                    <p>{{ notification.article.title }}</p>
                    <img v-if="notification.article.thumbnail" :src="$root.cloudinaryUrl + 'c_fill,f_auto,g_center,h_128,dpr_' + $root.$dpr + ',q_auto/v1' + notification.article.thumbnail" alt="Notification Article">
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            notifications: [],
            buttonDiv: null
        }
    },
    mounted() {

    },
    created() {
        if (this.$root.user === false) {
            return false;
        }
        this.$http.get('/api/v1/notifications').then(response => {
            this.notifications = response.body;
        }, response => {
            console.log('Error:');
            console.log(response);
        });
    },
    computed: {
        notifiers() {
            let n = [];
            for (let i = 0; i < this.notifications.length; i++) {
                if (typeof this.notifications[i].notifiers == 'undefined') {
                    continue;
                }
                let nfiers = this.notifications[i].notifiers;
                let notifierMessage = '';
                for (let j = 0; j < nfiers.length; j++) {
                    if (j === 1 && nfiers.length === 2) {
                        notifierMessage += ' and ';
                    } else if (j === 1 && nfiers.length > 2) {
                        notifierMessage += ', ';
                    }
                    if (j < 2) {
                        notifierMessage += '<a href="/u/' + nfiers[j].username + '">' + nfiers[j].username + '</a>';
                    } else if (j === 2) {
                        notifierMessage += ' and ' + (nfiers.length - 2) + ' other' + (nfiers.length > 3 ? 's' : '');
                    } else {
                        break;
                    }
                }
                n[i] = notifierMessage;
            }
            return n;
        },
        buttons() {
            let buttons = [];
            for (let i = 0; i < this.notifications.length; i++) {
                let ugcImg = this.notifications[i].button.match(/"(\/uploads\/.+?)"/);
                if (ugcImg && ugcImg.length === 2) {
                    let ugcImgCdn = this.$simpleStore.cloudinaryUrl(64, 64, ugcImg[1], this.$root);
                    buttons.push(this.notifications[i].button.replace(/"\/uploads\/.+?"/, ugcImgCdn));
                } else {
                    buttons.push(this.notifications[i].button);
                }
            }
            return buttons;
        }
    },
    methods: {
        follow(userId) {
            this.$simpleStore.follow(this, userId);
            let button = document.createElement('BUTTON');
            button.setAttribute('class', 'c-btn3 c-btn--following');
            button.setAttribute('data-method', 'unfollow');
            button.setAttribute('data-parameter', userId);
            button.textContent = 'Following';
            this.buttonDiv.replaceChild(button, this.buttonDiv.childNodes[0]);
        },
        unfollow(userId) {
            this.$simpleStore.unfollow(this, userId);
            let button = document.createElement('BUTTON');
            button.setAttribute('class', 'c-btn c-btn--follow');
            button.setAttribute('data-method', 'follow');
            button.setAttribute('data-parameter', userId);
            button.textContent = 'Follow';
            this.buttonDiv.replaceChild(button, this.buttonDiv.childNodes[0]);
        },
        handleClick(e, index) {
            // Call method dynamically if button click
            // method name set on button with data-method and data-parameter attributes on <button>
            if (e.target.tagName === 'BUTTON') {
                this.buttonDiv = e.target.parentElement;
                this[e.target.dataset.method](e.target.dataset.parameter);
            }
        }
    }
}
</script>
