import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        count: 0,
        mediaSize: 'xl',
        tour: []
    },
    mutations: {
        increment(state) {
            state.count++;
        },
        startTour(state, vue) {
            for (let i in vue.tour) {
                if (typeof vue.tour[i]['crossDevice'] != 'undefined' && vue.tour[i]['crossDevice'] === false) {
                    vue.tour[i]['target'] = ['xs', 's', 'm'].includes(this.getters.mediaSize) ? '[data-tour-mobile="' + vue.tour[i]['target'] + '"]' : '[data-tour-desktop="' + vue.tour[i]['target'] + '"]';
                } else {
                    vue.tour[i]['target'] = '[data-tour="' + vue.tour[i]['target'] + '"]';
                }
            }
            state.tour = vue.tour;
        }
    },
    getters: {
        mediaSize: state => {
            let mediaSize = 'xl';
            if (window.matchMedia("(max-width: 375px)").matches) {
                mediaSize = 'xs';
            } else if (window.matchMedia("(max-width: 479px)").matches) {
                mediaSize = 's';
            } else if (window.matchMedia("(max-width: 768px)").matches) {
                mediaSize = 'm';
            } else if (window.matchMedia("(max-width: 1020px)").matches) {
                mediaSize = 'l';
            }
            return mediaSize;
        },
        isMobile: state => {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
            } else {
                return false;
            }
        },
        tour: state => state.tour
    }
});
