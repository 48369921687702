export default {
    errorClass: 'c-form-error',
    blinkingClass: 'blinking',
    fadeOutAfter: 3500,
    fadeAnimationTime: 2000, // see animation time for CSS rule .c-fixed-message.fade-out
    removeErrorMessages() {
        // remove any existing error messages
        let errorMessages = document.getElementsByClassName(this.errorClass);
        while (errorMessages.length > 0) {
            errorMessages[0].remove();
            errorMessages = document.getElementsByClassName(this.errorClass);
        }
    },
    showGlobalError($refs, message) {
        $refs[message].classList.add('show');
        // add fade out class
        setTimeout(() => { $refs[message].classList.add('fade-out'); }, this.fadeOutAfter);
        // reset this.message
        setTimeout(() => { $refs[message].classList.remove('show', 'fade-out'); }, this.fadeOutAfter + this.fadeAnimationTime);
    },
    showFieldError($refs, field, errorMessage) {
        let errorDiv = document.createElement('div');
        errorDiv.classList.add(this.errorClass);
        errorDiv.classList.add(this.blinkingClass);
        errorDiv.textContent = errorMessage;
        if ($refs.hasOwnProperty(field)) {
            $refs[field].parentElement.append(errorDiv);
        }
    }
};
