var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-panel__content c-panel__content--notifications",
      attrs: { id: "notifications" },
    },
    [
      _vm.notifications.length === 0
        ? _c("div", { staticClass: "c-panel__wrap" }, [
            _c(
              "a",
              { staticClass: "c-panel__notification", attrs: { href: "#" } },
              [
                _vm.$root.user !== false
                  ? _c("div", { staticClass: "c-panel__notification-wrap" }, [
                      _vm._v(
                        "\n                No new notifications on your account\n            "
                      ),
                    ])
                  : _c("div", { staticClass: "c-panel__notification-wrap" }, [
                      _vm._v(
                        "\n                Notifications on your account will appear once logged in\n            "
                      ),
                    ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.notifications, function (notification, index) {
        return _c("div", { staticClass: "c-panel__wrap" }, [
          _c(
            "a",
            {
              class:
                "c-panel__notification" +
                (notification.read === false ? " new" : ""),
              attrs: { href: "#" },
            },
            [
              _c("div", { staticClass: "c-panel__notification-wrap" }, [
                _c(
                  "div",
                  { staticClass: "c-panel__notification-content button" },
                  [
                    _c("div", { staticClass: "c-panel__notification-detail" }, [
                      _c("div", { staticClass: "c-avatar" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.$simpleStore.cloudinaryUrl(
                              23,
                              23,
                              notification.notifierAvatar,
                              _vm.$root
                            ),
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", {
                          domProps: { innerHTML: _vm._s(_vm.notifiers[index]) },
                        }),
                        _vm._v(" "),
                        typeof notification.icon != "undefined"
                          ? _c("img", {
                              attrs: { src: notification.icon, alt: "" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(notification.message))]),
                        _vm._v(" "),
                        typeof notification.communityUrl != "undefined"
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: "/" + notification.communityUrl,
                                },
                              },
                              [_vm._v(_vm._s(notification.communityName))]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "c-panel__notification-btn",
                  domProps: { innerHTML: _vm._s(_vm.buttons[index]) },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick($event, index)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              typeof notification.article != "undefined"
                ? _c("div", { staticClass: "c-notifications-article" }, [
                    _c("p", [_vm._v(_vm._s(notification.article.title))]),
                    _vm._v(" "),
                    notification.article.thumbnail
                      ? _c("img", {
                          attrs: {
                            src:
                              _vm.$root.cloudinaryUrl +
                              "c_fill,f_auto,g_center,h_128,dpr_" +
                              _vm.$root.$dpr +
                              ",q_auto/v1" +
                              notification.article.thumbnail,
                            alt: "Notification Article",
                          },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }